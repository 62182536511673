import React from "react";
import bg from "../../images/bg.jpg";

const Header = ({ clickScroll }) => {
  return (
    <div id="header">
      <div>
        <h2>Arise and Shine</h2>
        <h4>
          May the LORD bless you from Zion<small>Psalms 128:5</small>
        </h4>
      </div>
      <img src={bg} alt={bg} />
      <p className="mouse-wrapper" onClick={() => clickScroll("joinusRef")}>
        <p className="mouse-icon" />
        <p className="mouse-arrow" />
      </p>
    </div>
  );
};

export default Header;
