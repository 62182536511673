import React from "react"
import {
  FaFacebookSquare,
  FaYoutube,
  FaInstagram,
  FaEnvelope,
  FaPhoneSquare,
} from "react-icons/fa"

const Follow = () => {
  return (
    <div id="follow">
      <ul>
        <li>
          <a target="_blank" href="//www.facebook.com/thehermonchurch">
            <FaFacebookSquare />
          </a>
        </li>
        <li>
          <a target="_blank" href="//www.youtube.com/c/HermonPrayerHall/">
            <FaYoutube />
          </a>
        </li>
        <li>
          <a target="_blank" href="//www.instagram.com/hermonprayerhall/">
            <FaInstagram />
          </a>
        </li>
        <li>
          <a target="_blank" href="mailto:hello@hermonprayerhall.com">
            <FaEnvelope />
          </a>
        </li>
        <li>
          <a target="_blank" href="tel:7702277007">
            <FaPhoneSquare />
          </a>
        </li>
      </ul>
      <p className="copy">
        2020 © Hermon Welfare Association | All Rights Reserved
      </p>
    </div>
  )
}

export default Follow
