import React from "react";

const Fellowship = () => {
  return (
    <div className="section fellowship">
      <div className="container">
        <div className="fl_tbl">
          <h4 className="heading-ttl center-align">Fellowship</h4>
          <br />
          <div className="fl_tbl_wrpr">
            <div className="item sunsch sun">
              <p className="title">Sunday School</p>
              <p className="time">
                <span className="day">Sunday</span>
                <span className="large">
                  8:00<small>AM</small>
                </span>
              </p>
            </div>
            <div className="item sunwor sun">
              <p className="title">Worship Service</p>
              <p className="time">
                <span className="day">Sunday</span>
                <span className="large">
                  8:00<small>AM</small>
                </span>
              </p>
            </div>
            <div className="item hspr mon">
              <p className="title">House Outreach</p>
              <p className="time">
                <span className="day">Monday</span>
                <span className="large">
                  7:00<small>PM</small>
                </span>
              </p>
            </div>
            <div className="item fstpr tue">
              <p className="title">Fasting Prayer</p>
              <p className="time">
                <span className="day">Tuesday</span>
                <span className="large">
                  10:00<small>AM</small>
                </span>
              </p>
            </div>
            <div className="item peipr wed">
              <p className="title">Praise Fellowship</p>
              <p className="time">
                <span className="day">Wednessday</span>
                <span className="large">
                  7:00<small>PM</small>
                </span>
              </p>
            </div>
            <div className="item peipr thu">
              <p className="title">Praise Fellowship</p>
              <p className="time">
                <span className="day">Thursday</span>
                <span className="large">
                  7:00<small>PM</small>
                </span>
              </p>
            </div>
            <div className="item wmnpr fri">
              <p className="title">Women's Fellowship</p>
              <p className="time">
                <span className="day">Friday</span>
                <span className="large">
                  10:00<small>AM</small>
                </span>
              </p>
            </div>
            <div className="item fstpr sat">
              <p className="title">Fasting Prayer</p>
              <p className="time">
                <span className="day">Saturday</span>
                <span className="large">
                  7:00<small>PM</small>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Fellowship;
