import React from "react";

const Joinus = () => {
  return (
    <div className="section some">
      <div className="container">
        <div className="joinus">
          <h4 className="heading-ttl center-align solid">Join US</h4>
          <h4 className="med-heading-ttl ">
            Welcome to Hermon Prayer Hall's web portal. We are glad you are here
            and hope that you will find who we are and what we do.
          </h4>
          <br />
          <p className="">
            We are a family, from all walks of life,{" "}
            <strong className="uline">
              found a way to the father by the grace of holy spirit and being
              transformed by Jesus Christ
            </strong>{" "}
            We are Passionate about sharing the love of Christ by caring for
            each other. We would like to welcome you to our service this Sunday.
            Come just as you are!
          </p>
          <br />
          <p className="">
            Don't be confused. Any doubts or questions concerning your first
            visit to our church we will assure make every effort to ensure that
            your first experience is comfortable. We look forward to spending
            time with you and becoming friends. We pray that you will find in
            our midst a warm and friendly atmosphere, a great worship service,
            and a relevant Christ-centered message.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Joinus;
