import React from "react";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";

const MapComponent = props => {
  const mapStyles = {
    width: "100%",
    height: "100%"
  };
  return (
    <Map
      google={props.google}
      zoom={15}
      style={mapStyles}
      initialCenter={{ lat: 17.6684678, lng: 83.212597 }}
    >
      <Marker position={{ lat: 17.669137, lng: 83.2127147 }} />
    </Map>
  );
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyCC4O-gT_xtSOgI_bg7AgWTw99smPjCpTQ"
})(MapComponent);
