import React from "react"
import dayaji from "../../images/about-1-both.jpg"
import pastma from "../../images/about-1-patam.jpg"
import satanna from "../../images/about-1-sat.jpg"
import rakanna from "../../images/about-1-rak.jpg"
import marakka from "../../images/about-1-mar.jpg"
import samanna from "../../images/about-1-sam.jpg"
import ruthakka from "../../images/about-1-rut.jpg"
import divyakka from "../../images/about-1-div.jpg"

const About = () => {
  return (
    <div className="section about">
      <div className="img_container">
        <div className="container">
          <h4 className="heading-ttl center-align">About</h4>
          <span className="img" />
        </div>
      </div>
      <div className="container">
        <div className="profiles">
          <div className="about_wisheh">
            <strong>Hermon Prayer Hall,</strong>
            <span>
              a 30 years ministry, built on faith and love by church planters
              Pastor Dayanandam & Manoharamma. Being led by Pastor Sateesh &
              family Our church communicates God’s Word through evangelism. As
              long as there is one person in the our who doesn’t know Christ,
              our church will continue to reach out. We believe the church is to
              minister to all kinds of needs: spiritual, emotional, relational,
              and physical. Every time you reach out in love to someone else,
              you are ministering to them.
            </span>
          </div>
        </div>
        <div className="profiles_list">
          <div className="profItem">
            <span className="profImg">
              <img src={dayaji} alt="Rev. Pastor V Dayanandam (Late)" />
            </span>
            <span className="texto">
              <span className="titles">
                <small>
                  Rev. Pastor V Dayanandam(Late) <br />
                  and
                  <br /> V Manoharamma
                </small>
              </span>
              <span className="designations">
                <small>Founders and Directors</small>
              </span>
            </span>
            <span className="profImg">
              <img src={pastma} alt="V Manoharamma" />
            </span>
          </div>
        </div>
        <div className="leading_pastors">
          <div className="leading_pastors_list">
            <div className="leading_pastors_item">
              <span className="profImg">
                <img src={satanna} alt="Pastor V Sateesh" />
              </span>
              <span className="texto">
                <span className="titles">
                  <small>Pastor V Sateesh</small>
                </span>
              </span>
            </div>
            <div className="leading_pastors_item">
              <span className="profImg">
                <img src={divyakka} alt="V Divya" />
              </span>
              <span className="texto">
                <span className="titles">
                  <small>V Divya</small>
                </span>
              </span>
            </div>
          </div>
          <div className="leading_pastors_list">
            <div className="leading_pastors_item">
              <span className="profImg">
                <img src={rakanna} alt="Pastor V Rakesh" />
              </span>
              <span className="texto">
                <span className="titles">
                  <small>Pastor V Rakesh</small>
                </span>
              </span>
            </div>
            <div className="leading_pastors_item">
              <span className="profImg">
                <img src={ruthakka} alt="V Ruth" />
              </span>
              <span className="texto">
                <span className="titles">
                  <small>V Ruth</small>
                </span>
              </span>
            </div>
            <div className="leading_pastors_item">
              <span className="profImg">
                <img src={samanna} alt="Pastor V Shyamgar" />
              </span>
              <span className="texto">
                <span className="titles">
                  <small>Pastor V Shyamgar</small>
                </span>
              </span>
            </div>

            <div className="leading_pastors_item">
              <span className="profImg">
                <img src={marakka} alt="V Mareena" />
              </span>
              <span className="texto">
                <span className="titles">
                  <small>V Mareena</small>
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
