import React from "react";
import { FiMapPin, FiMail, FiPhone } from "react-icons/fi";
import MapComponent from "../MapComponent";

const Contact = () => {
  return (
    <div className="section contact">
      <div className="container">
        <div className="fl_tbl">
          <h4 className="heading-ttl center-align solid">Get in touch</h4>
          <br />
          <div className="trips">
            <div className="trips_left">
              <strong>Contact</strong>
              <ul>
                <li>
                  <FiMapPin />
                  <span>
                    Opp. Dharmakata, Balacheruvu Rd, Industrial Area,
                    Pedagantyada, Visakhapatnam, Andhra Pradesh 530044
                  </span>
                </li>
                <li>
                  <FiMail />
                  <a href="mailto:hello@hermonprayerhall.com">
                    hello@hermonprayerhall.com
                  </a>
                </li>
                <li>
                  <FiPhone />
                  <a href="tel:+917702277007">77022 77007</a>
                </li>
                <li>
                  <FiPhone />
                  <a href="tel:+919492240201">94922 40201</a>
                </li>
              </ul>
            </div>
            <div className="trips_center">
              <strong>Message us</strong>
              <div className="form">
                <div className="form-row">
                  <input type="text" placeholder="Name*" />
                </div>
                <div className="form-row">
                  <input type="text" placeholder="Phone Number (Optional)" />
                </div>
                <div className="form-row">
                  <textarea placeholder="Name" />
                </div>
                <div className="form-row">
                  <button>Submit</button>
                </div>
              </div>
            </div>

            <div className="trips_right">
              <MapComponent />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
