import React, { useState } from "react"
import SEO from "../components/seo"
import Layout from "../components/Layout"
import Header from "../components/main/Header"
import Joinus from "../components/main/Joinus"
import Fellowship from "../components/main/Fellowship"
import Contact from "../components/main/Contact"
import About from "../components/main/About"
import Follow from "../components/main/Follow"

const Index = () => {
  const joinusRef = React.createRef()
  const fellowshipRef = React.createRef()
  const contactRef = React.createRef()
  const aboutRef = React.createRef()
  const headerRef = React.createRef()
  const followRef = React.createRef()
  const [language, setLanguage] = useState()
  function scroller(refstr) {
    if (refstr === "joinusRef") {
      joinusRef.current.scrollIntoView({ block: "center", behavior: "smooth" })
    }
    if (refstr === "fellowshipRef") {
      fellowshipRef.current.scrollIntoView({
        block: "center",
        behavior: "smooth",
      })
    }
    if (refstr === "contactRef") {
      contactRef.current.scrollIntoView({
        block: "center",
        behavior: "smooth",
      })
    }
    if (refstr === "aboutRef") {
      aboutRef.current.scrollIntoView({ block: "center", behavior: "smooth" })
    }
    if (refstr === "headerRef") {
      headerRef.current.scrollIntoView({ block: "center", behavior: "smooth" })
    }

    if (refstr === "followRef") {
      followRef.current.scrollIntoView({ block: "center", behavior: "smooth" })
    }
  }
  return (
    <Layout
      page="home"
      clickScroll={refstr => scroller(refstr)}
      getLang={lang => setLanguage(lang)}
    >
      <SEO title="Home" />
      <div id="body">
        <div id="header" ref={headerRef}>
          <Header clickScroll={refstr => scroller(refstr)} />
        </div>
        <div id="joinus" ref={joinusRef}>
          <Joinus />
        </div>
        <div id="fellowship" ref={fellowshipRef}>
          <Fellowship />
        </div>
        <div id="contact" ref={contactRef}>
          <Contact />
        </div>
        <div id="about" ref={aboutRef}>
          <About />
        </div>

        <div id="Follow" ref={followRef}>
          <Follow />
        </div>
      </div>
    </Layout>
  )
}

export default Index
